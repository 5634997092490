const clocks = [...document.querySelectorAll('.local-time-clock')];

const observer = new IntersectionObserver((entries) => entries.forEach(entry => entry.target.classList.toggle('is-in-viewport', !!entry.intersectionRatio)), {
  root: null,
  rootMargin: '0px',
  threshold: [0, 1],
});

clocks.forEach((clock) => observer.observe(clock));

setInterval(() => {
  if (document.visibilityState !== 'visible') return;
  const time = new Date().toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: "short", timeZone: localTimeClock!.timeZone });
  clocks.forEach(clock => clock.querySelector('.local-time-clock-time')!.textContent = time);
}, 500);